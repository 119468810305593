/*
 * @Author: your name
 * @Date: 2021-12-29 16:44:16
 * @LastEditTime: 2024-07-24 10:10:03
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/index.tsx
 */
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './router/router';
import store from './redux/stroe';
import './assets/style/antd.scss';
import './assets/style/global.scss';
import './assets/iconfont/RelayIcon.css';
import initI18n from './i18n';
import {setVnStyle , littleWindow} from '@/util/init.js'
import { debounce } from 'lodash';
import '@/util/langs'

setVnStyle();
littleWindow();

//根据浏览器窗口变化缩放页面 临时方案
window.onresize = debounce(function(){
  littleWindow();
}, 200)

initI18n(() => {
  ReactDOM.render(
    <Provider store={store}>
      <Router></Router>
    </Provider>,

    document.getElementById('root'),
  );
});
