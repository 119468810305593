import i18next from 'i18next';
import React from 'react';
import { removeLocal, getLocal, LocalNameEnum } from '@/util/storage';
import { CopyOutlined } from '@ant-design/icons'
import { message } from 'antd';

function bytesToBase64(text: string) {
  const bytes = new TextEncoder().encode(text);
  const binString = Array.from(bytes, byte => String.fromCodePoint(byte)).join('');
  return btoa(binString);
}

const TLT = (text: string | number, params?: Record<string, string>) => {
  const key = bytesToBase64(String(text).trim());
  return i18next.exists(key) ? i18next.t(key, params || {}) : String(text).trim();
};

// TLT = translate language tools
window.TLT = TLT;

const copy = (text: string) => {
  if (navigator.clipboard && window.isSecureContext) {
      // 使用 Clipboard API
      navigator.clipboard.writeText(text).then(() => {
        message.success('复制成功');
      }).catch((err) => {
          console.error('复制失败', err);
      });
  }
};

// if (getLocal(LocalNameEnum.mulLangLocalTag) === 'showTag') {
//   removeLocal(LocalNameEnum.mulLangLocalTag);
//   window.TLT = (text: string | number, params?: Record<string, string>) => {
//     const key = bytesToBase64(String(text).trim());
//     return (
//       <span style={{ position: 'relative' }}>
//         { i18next.exists(key) ? i18next.t(key, params || {}) : String(text).trim() }
//         <span
//           style={{
//             position: 'absolute',
//             top: '-30px' /* 调整这个值以控制文案的垂直位置 */,
//             left: '50%',
//             transform: 'translateX(-50%)',
//             backgroundColor: 'rgba(0, 0, 0, 0.7)',
//             color: 'white',
//             padding: '5px 10px',
//             borderRadius: '5px',
//             whiteSpace: 'nowrap',
//           }}
//           key={text}
//         >{text}<CopyOutlined onClick={() => copy(text)} style={{ marginLeft: 5, cursor: 'pointer', color: 'red' }}/></span>
//       </span>
//     );
//   };
// }
