/*
 * @Author: your name
 * @Date: 2021-12-28 17:53:05
 * @LastEditTime: 2024-09-02 15:03:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/OrderList/components/Goods/index.tsx
 */
import React, { useState } from 'react';
import { Row, Col, message, Tooltip } from 'antd';
import Icon from '@/components/common/Icon';
import styles from '../../index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import iconX from '@/assets/images/orderList/icon_x.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { formatMoney, cnyMoney } from '@/util';
import Service from '@/components/ServiceStuff';
import { useTranslation } from 'react-i18next';
import ServiceStuff from '@/components/ServiceStuff';
import cookies from 'react-cookies';
import { useSelector } from 'react-redux';

const Goods: React.FC<{
  goods: any;
}> = ({ goods }) => {
  const { t } = useTranslation();
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土

    // 获取用户操作权限
    const userLimit: any = useSelector((state: any) => {
      return state?.UserLimit;
    });

  //以下没用
  // const data = goods.serviceSkus || [];
  // let serviceData: any = [];
  // if (data.length) {
  //   serviceData = goods?.extAttr?.giftAppendix ? data.concat(JSON.parse(goods?.extAttr?.giftAppendix)) : data;
  // } else {
  //   serviceData = goods?.extAttr?.giftAppendix ? JSON.parse(goods?.extAttr?.giftAppendix) : [];
  // }
  // if (serviceData.length > 0) {
  //   serviceData.forEach((service: any) => {
  //     service.type = service.skuType;
  //     service.skuName = service.name;
  //     service.serviceSkuPrice = service.salesPrice;
  //   });
  // }

  return (
    <div>
      <Row className={styles.borderTop}>
        <Col className={[styles.title1].join(' ')} style={{ padding: '20px', textAlign: 'right' }}>
          {goods.isService ? (
            <div style={{ width: '75px', height: '75px' }} />
          ) : (
            <img
              src={goods.skuImg}
              style={{ width: '75px', height: '75px', border: '1px solid rgba(0,0,0,0.07)' }}
            />
          )}
        </Col>
        <Col className={[styles.title2, styles.goodsCell].join(' ')} style={{ alignItems: 'flex-start' }}>
          {/*<Tooltip*/}
          {/*  color="#ffff"*/}
          {/*  overlayInnerStyle={{ color: 'rgba(0,0,0,0.85)', fontSize: '12px' }}*/}
          {/*  placement="bottomLeft"*/}
          {/*  title={goods.skuName}*/}
          {/*>*/}
          {/*{goods.marking && <em className={styles.biaopin}>工业标品</em>}*/}
          <a
            clstag="h|keycount|myorder|20"
            href={goods.sku ? `/sku/${goods.sku}` : undefined}
            className={styles.goodsName}
            rel="noreferrer"
            title={goods.skuName}
            target="_blank"
            onClick={e => {
              // if (!goods.sku) {
              //   e.preventDefault();
              //   message.error(`商品${goods.sku}不在商品池`);
              // }
            }}
          >
            {`${goods?.sourceCountryCode == 'CN' ? TLT(`【跨境】`) : ''}${goods.skuName}`}
          </a>
          {/*</Tooltip>*/}

          <Row style={{ marginTop: '0px' }}>
            <span style={{ color: 'rgba(102,102,102,0.65)' }}>{TLT(`商品型号`)}: </span>
            <span style={{ marginLeft: '10px', color: 'rgba(51,51,51,0.85)' }}>{goods.specifications}</span>
          </Row>

          <Row style={{ marginTop: '0px' }}>
            <span style={{ color: 'rgba(102,102,102,0.65)' }}>{TLT(`商品编码`)}: </span>
            <span style={{ marginLeft: '10px', color: 'rgba(51,51,51,0.85)' }}>{goods.sku}</span>
          </Row>

         {userLimit?.limitData?.enableMaterial == 1 && <Row style={{ marginTop: '0px' }}>
            <span style={{ color: 'rgba(102,102,102,0.65)' }}>{TLT(`物料编码`)}: </span>
            <span style={{ marginLeft: '10px', color: 'rgba(51,51,51,0.85)' }}>{goods?.mkuMaterial?.materialId || '-'}</span>
          </Row>}
        </Col>
        <Col className={[styles.title3, styles.goodsCell].join(' ')} style={{alignItems: 'flex-start' }}>
          {!isLocalPro && <Row>
            <span>{goods.symbol + cnyMoney(goods.sellingPrice, false)}</span>
            <Icon icon={iconX} style={{ marginLeft: '2px' }} />
            {goods.skuNum}
            {/*{goods?.extAttr?.x ?? '件'}*/}
          </Row>}
          {isLocalPro && <Row>
          <span style={{
              marginRight: '5px',
            }}>{TLT(`含税价`)}</span>
            {goods.symbol + formatMoney(goods.taxSalePrice, false)}
          </Row>}
          {isLocalPro && <Row>
          <span style={{
              marginRight: '5px',
            }}>{TLT(`未税价`)}</span>
            {goods.symbol + formatMoney(goods.skuPrice, false)}
          </Row>}
          {isLocalPro && <Row>
            <span style={{
              marginRight: '5px',
            }}>{TLT(`税率`)}</span>
            {goods.valueAddedTaxRate + '%' }
          </Row>}
          {isLocalPro && <Row>
            <Icon icon={iconX} style={{ marginLeft: '2px' }} />
            {goods.skuNum}
          </Row>}
         
        </Col>
        {isLocalPro && <Col className={[styles.title9, styles.goodsCell].join(' ')} style={{ marginTop: '0px' }}>{goods?.customsClearance == 1 ? TLT(`是`) : goods?.customsClearance == 0 ? TLT(`否`) : '-'}</Col>}
      </Row>
      <Row style={{ paddingLeft: '20px', marginTop: '-14px', marginBottom: '4px' }}>
        <ServiceStuff data={goods.gift} />
      </Row>
      
    </div>
  );
};

export default Goods;
